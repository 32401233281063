// const url = "https://nodepune-sniperbot.mobiloitte.io";  // staging
// const botSok = "https://pypune-arbitragebot.mobiloitte.io"; // staging


const url = "https://no.mbaacademy.asia";// client live url
const botSok = "https://python.mbaacademy.asia"; // client live url

// const url = "http://172.16.6.80:3053" // jayesh  local
// const url = "http://172.16.2.14:3053"; // suraj sir local

// const url = "https://node.mbaacademy.asia"; // liveurl
// const botSok = "http://13.126.109.55:5001"; // liveurl

// const botSok = "http://172.16.6.43:5001"; // Abhijit

let triangularArbitrage = `${url}/api/v1/arbitrage`;
let wallet = `${url}/api/v1/exchangeWallet`;
let notification = `${url}/api/v1/notification`;

const ApiConfig = {
  botSok,
  login: `${url}/api/v1/user/login`,
  signup: `${url}/api/v1/user/register`,
  verify: `${url}/api/v1/user/verify`,
  forgot: `${url}/api/v1/user/forgot`,
  changePassword: `${url}/api/v1/user/changePassword`,
  resend: `${url}/api/v1/user/resend`,
  profile: `${url}/api/v1/user/profile`,
  editProfile: `${url}/api/v1/user/editProfile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,
  kycApply: `${url}/api/v1/user/kycApply`,
  viewKyc: `${url}/api/v1/user/viewKyc`,
  planlist: `${url}/api/v1/plan/list`,
  updatePassword: `${url}/api/v1/user/updatePassword`,
  myWallets: `${url}/api/v1/user/myWallets`,
  deposit: `${url}/api/v1/user/deposit`,
  history: `${url}/api/v1/user/history`,
  withdraw: `${url}/api/v1/user/withdraw`,
  genOtp: `${url}/api/v1/user/genOtp`,
  fsfs: `${url}/api/v1/user/buy/`,
  activePlan: `${url}/api/v1/user/activePlan`,
  updateSniperTradeCount: `${url}/api/v1/user/updateSniperTradeCount`,
  checkPlan: `${url}/api/v1/user/checkPlan`,
  checkSniperPlan: `${url}/api/v1/user/checkSniperPlan`,
  getUserWalletList: `${url}/api/v1/user/getUserWalletList`,
  orderList:`${url}/api/v1/user/getOrderList`,
  listExchange: `${url}/api/v1/user/listExchange`,
  connectExchange: `${url}/api/v1/user/connectExchange`,
  connectedExchangeList: `${url}/api/v1/user/connectedExchangeList`,
  removeConnectedExchange: `${url}/api/v1/user/removeConnectedExchange`,
  transationHistory:`${url}/api/v1/user/transationHistory`,

// dashboard
DashboardCount: `${url}/api/v1/user/Dashboard`,
sniperdashboardCount:`${botSok}/userDashboardCount`,

  // Admin
  viewUserActivePlan: `${url}/api/v1/user/viewUserActivePlan`,

  //BotSetting
  multiStop: `${botSok}/stop`,
  start: `${botSok}/start`,
  checkPrivate_key: `${botSok}/checkPrivate_key`,
  addWallet:`${botSok}/addWallet`,
  getWallet:`${botSok}/getWallet`,
  tokenDeatils: `${botSok}/tokenDeatils`,

  // ArbitrageFuelFees
  viewDailyFuelFees:`${url}/api/v1/user/viewDailyFuelFees`,
  payFuelFees:`${url}/api/v1/user/payFuelFees`,
  viewPayTransactionHistory:`${url}/api/v1/user/viewPayTransactionHistory`,


  // Arbitrage
  getTotalProfitPath:`${url}/api/v1/user/getTotalProfitPath`,
  getTriangularTotalProfitPath:`${url}/api/v1/user/getTriangularTotalProfitPath`,

  //snipertransaction
  activeWallet:`${botSok}/activeWallet`,
  boutghtcoins:`${botSok}/boutghtcoins`,
  sellcoins:`${botSok}/sellcoins`,
  foundcoins:`${botSok}/foundcoins`,
  keepholding:`${botSok}/keepholding`,
  miniAudits:`${botSok}/miniAudits`,
  tradeInfo:`${botSok}/tradeInfo`,
 

  // pair Details
  getTickerData:`${url}/api/v1/user/getTickerData`,


  // setting
  updateInitialAmount:`${url}/api/v1/user/updateInitialAmount`,
  viewInitialAmount:`${url}/api/v1/user/viewInitialAmount`,
  viewUserPlan:`${url}/api/v1/user/viewUserPlan`,


  // Affialiate
  affiliate: `${url}/api/v1/affiliate/affiliate`,
  affiliateList: `${url}/api/v1/affiliate/affiliateList`,
  getUserDetails: `${url}/api/v1/affiliate/getUserDetails`,
  staticContent: `${url}/api/v1/static/static`,
  getTotalAffiliatePrice: `${url}/api/v1/affiliate/getTotalAffiliatePrice`,

  //get salary income
  getSalaryIncome: `${url}/api/v1/affiliate/getSalaryIncome`,

  //prelaunching offer
  getPreLaunchingOffer: `${url}/api/v1/affiliate/getPreLaunchingOffer`,

  //getRewardIncome
  getRewardIncome: `${url}/api/v1/affiliate/getRewardIncome`,

  // trading Bonus
  getTradingIncome: `${url}/api/v1/affiliate/getTradingIncome`,

  //notification
  listNotification: `${notification}/listNotification`,
  readNotification: `${notification}/readNotification`,
  clearNotification: `${notification}/clearNotification`,
  readNotification: `${notification}/readNotification`,

  //Triangular
  filterProfitPathsTriangular: `${triangularArbitrage}/filterProfitPaths`,
  filterProfitPathsTriangularGo: `${triangularArbitrage}/filterProfitPaths`,
  tradeProfitPathsTriangular: `${triangularArbitrage}/tradeProfitPaths`,
  listPlacedTradeTriangular: `${triangularArbitrage}/listPlacedTrade`,
  viewPlacedTradeTriangular: `${triangularArbitrage}/viewPlacedTrade`,
  activeBlockvPlacedTradeTriangular: `${triangularArbitrage}/activeBlockPlacedTrade`,
  deletePlacedTradeTriangular: `${triangularArbitrage}/deletePlacedTrade`,
  cancelledOrderTriangular: `${triangularArbitrage}/cancelledOrder`,
  autoTradeOnOffTriangular: `${triangularArbitrage}/autoTradeOnOff`,

  //wallet
  serverIPAddress: `${wallet}/serverIPAddress`,
  exchangeCoins: `${wallet}/exchangeCoins`,
  asks_bids_prices: `${wallet}/asks_bids_prices`,
  mexcPairList: `${wallet}/mexcPairList`,
  generateAddress: `${wallet}/generateAddress`,
  getWithdrawAddress: `${wallet}/getWithdrawAddress`,
  exchangeBalance: `${wallet}/exchangeBalance`,
  withdrawHistory: `${wallet}/withdrawHistoryy`,
  Dashboard: `${wallet}/Dashboard`,
  withdrawDepositeHistory: `${wallet}/withdrawDepositeHistory`,
  statistic: `${wallet}/statistic`,
  get_wallet_coinImageData: `${wallet}/coinImageData`,



};
export default ApiConfig;
